<!--
 * @Description: 巡查管理--巡查点管理--列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: FCC
 * @LastEditTime: 2020-04-29 18:02:35
 -->
<template>
  <div class="inspectManagerPointList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :multiExport="multiExport"
      :page-size="200"
      @searchSuccess="searchSuccess"
    >
      <template #headerSlot="scope">
        <v-button text="生成本次巡查任务" permission="generateNow" @click="generateTask(scope.selectedData)" :disabled="scope.selectedData.data.length==0"></v-button>
        <v-button text="批量修改" permission="update" @click="batchEdit"></v-button>
        <el-dropdown trigger="click" @command="importClick" v-if="permission.inspectUserConfig||permission.pointConfig">
          <v-button>
            巡查配置<i class="el-icon-arrow-down el-icon--right"></i>
          </v-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{type: 1}" v-show="permission.inspectUserConfig">
              <v-button  type="text">巡查人员配置</v-button>
            </el-dropdown-item>
            <el-dropdown-item :command="{type: 2}" v-show="permission.pointConfig">
               <v-button  type="text">巡查点配置</v-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <v-button text="巡查人员配置" permission="inspectUserConfig" @click="toPersonPoint"></v-button>
        <v-button text="巡查点配置" permission="pointConfig" @click="toConfigPoint"></v-button> -->
        <v-button text="新增" permission="add" @click="updateAcion"></v-button>
        <v-button
          text="导入"
          permission="import"
          @click="importHandle"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-input label="巡查地点" v-model="searchParams.site"></v-input>
        <v-select
          label="巡查类型"
          v-model="searchParams.type"
          :options="typeOps"
        ></v-select>
        <v-select
          label="巡查频次"
          v-model="searchParams.period"
          :options="periodOps"
        ></v-select>
        <v-select
          label="状态"
          v-model="searchParams.pointStatus"
          :options="statusOps"
        ></v-select>
        <v-datepicker
          label="规则生效时间段"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
        />
        <v-select
          label="巡查方式"
          v-model="searchParams.pattern"
          :options="patternOps"
        ></v-select>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select2
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
        ></v-select2>
        <v-datepicker
          label="最后操作时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.operateStartTime"
          :endTime.sync="searchParams.operateEndTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="updateAcion(scope.row)"
        ></v-button>
        <v-button
          text="删除"
          type="text"
          v-if="scope.row.pointStatus==2"
          permission="delete"
          @click="deleteHandle(scope.row)"
        ></v-button>
        <v-button
          v-if="!showMore"
          :text="onoffText(scope.row)"
          type="text"
          permission="on_off"
          @click="batchOnOffHandle(scope.row)"
        ></v-button>
        <v-button
          v-if="!showMore"
          text="二维码"
          type="text"
          permission="qrcode"
          @click="lookQRCodeHandle(scope.row)"
        ></v-button>
        <el-dropdown trigger="click" @command="moreHandle" v-if="showMore">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ row: scope.row, type: 1 }">
              <span>{{ onoffText(scope.row) }}</span>
            </el-dropdown-item>
            <el-dropdown-item :command="{ row: scope.row, type: 2 }">
              <span>二维码</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量生成二维码" :disabled="scope.selectedData.data.length === 0" permission="qrcode" @click="batchQRCodeHandle(scope.selectedData.data)"></v-button>
        <v-button
          text="批量开启"
          permission="on_off"
          @click="batchOnOffHandle(scope.selectedData, 1)"
        ></v-button>
        <v-button
          text="批量停用"
          permission="on_off"
          @click="batchOnOffHandle(scope.selectedData, 2)"
        ></v-button>
        <v-button
          text="批量删除"
          permission="batchDelete"
          @click="batchDeleteHandle(scope.selectedData)"
        ></v-button>
        <!-- <v-button
          text="批量生成二维码"
          permission="qrcode"
          @click="batchQRCodeHandle(scope.selectedData)"
        ></v-button> -->
      </template>
      <el-dialog
        title="下载二维码"
        width="500px"
        :visible.sync="dialogVisible"
        @closed="closeDialog"
      >
        <div class="qrcode-wrapper">
          <div>
            <h3 class="title-text">
              {{ qrcodeSite }}
            </h3>
          </div>
          <div class="qrcode-value">
            <span> 二维码值：{{ qrcodeValue }} </span>
          </div>
          <div class="qrcode-img-wrapper">
            <div v-if="qrCodeId" :id="qrCodeId" class="img"></div>
          </div>
          <v-button
            @click="downloadQRCode"
            text="保存到本地"
            type="success"
          ></v-button>
        </div>
      </el-dialog>
      <qrcode-collection
        :qrcode-list="qrcodeList"
        :visible.sync="qrcodeCollectionShow"
      >
        <template #qrcodeSlot="scope">
          <span>{{scope.row.site}}</span>
        </template>
      </qrcode-collection>
    </list>
    <el-dialog
        title="巡查人员配置"
        width="800px"
        :visible.sync="dialogPersonConfig"
        @close="dialogPersonConfigClose"
      >
      <div class="patrol-point-wrapper">
       <v-select2
          v-if="userInfo.userType !== '101'"
          ref="personConfigCommunityId"
          label="所属项目"
          placeholder="查询项目"
          v-model="personConfig.communityId"
          v-bind="communityParams"
          @onChange="communityChange"
        ></v-select2>
        <div v-if="personConfig.communityId">
          <div class="count-label">
            项目共 {{communityHouseInfo.houseCount}} 个房号，尚未指定巡查人员的房号有 <span class="red-color">{{communityHouseInfo.nonPatrolCount}}</span> 个
          </div>
           <div class="count-label">
            项目共 {{PointInfo.pointCount}} 个巡查点，尚未指定巡查人员的点位有 <span class="red-color">{{PointInfo.nonPatrolCount}}</span> 个
          </div>
          <div class="add-box">
            <v-button text="新增人员" @click="addPerson"></v-button>
          </div>
          <table-panel
            ref="personTable"
            :headers="personHeader"
            maxHeight="300px"
            :tableData="personList">
            <template #operateSlot="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">编辑</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <v-button text="编辑巡查房号" type="text" @click="editPatrolHouse(scope.row)"></v-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                   <v-button text="编辑巡查点" type="text" @click="editPatrolPoint(scope.row)"></v-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              &nbsp;
              <v-button text="删除" type="text" @click="deletePatrol(scope.row)"></v-button>
            </template>
          </table-panel>
        </div>

      </div>
    </el-dialog>
    <el-dialog
        title="巡查点配置"
        width="800px"
        :visible.sync="dialogPointConfig"
        @close="dialogPointConfigClose"
      >
      <div class="patrol-point-wrapper">
        <v-select2
            v-if="userInfo.userType !== '101' && userInfo.userType !== '102'"
            ref="patrolPointRegionId"
            label="所属公司"
            v-model="patrolPoint.regionId"
            v-bind="tenantParams"
            @onChange="regionChange"
          ></v-select2>
          <div class="point-panel" v-if="patrolPoint.regionId || userInfo.userType === '102' || userInfo.userType === '101'">
            <!-- 空关房 -->
            <div class="col2-panel">
              <v-checkbox v-model="patrolPointConfig.isAutoGenerateEmptyRoomPoint" :disabled="permission.autoGenEmptyRoom !== 1" class="inlineB" label="自动生成空关房巡查点"></v-checkbox>
              <div class="tip">开启后，若房屋状态为空关，则会按以下规则自动生成空关房巡查点，并生成巡查任务给指定人员</div>
              <div class="point-opera" v-if="patrolPointConfig.isAutoGenerateEmptyRoomPoint">
                <v-select
                  class="select-container"
                  v-model="patrolPoint.firstCategory"
                  :disabled="permission.editEmptyRoomType !== 1"
                  :options="firstCategoryList"
                  :width="120"
                  @change="firstCategoryChange(2,$event)"
                ></v-select>
                <v-select
                  class="select-container"
                  v-model="patrolPointConfig.emptyRoomPointCategoryId"
                  :disabled="permission.editEmptyRoomType !== 1"
                  :options="secondCategoryList"
                  :width="120"
                ></v-select>
                <v-button text="巡查频次" v-if="patrolPoint.firstCategory && patrolPointConfig.emptyRoomPointCategoryId" :disabled="permission.editInspectFrequency !== 1" @click="getCategoryInfo(1)"></v-button>
              </div>
            </div>
            <!-- 空置房 -->
            <div class="col2-panel">
              <v-checkbox v-model="patrolPointConfig.isAutoGenerateVacantRoomPoint" :disabled="permission.autoGenVacantRoom !== 1" class="inlineB" label="自动生成空置房巡查点"></v-checkbox>
              <div class="tip">开启后，若房屋状态为空置，则会按以下规则自动生成空置房巡查点，并生成巡查任务给指定人员</div>
              <div class="point-opera" v-if="patrolPointConfig.isAutoGenerateVacantRoomPoint">
                <v-select
                  class="select-container"
                  v-model="patrolPoint.firstCategory4"
                  :disabled="permission.editVacantRoomType !== 1"
                  :options="firstCategoryList4"
                  :width="120"
                  @change="firstCategoryChange(4,$event)"
                ></v-select>
                <v-select
                  class="select-container"
                  v-model="patrolPointConfig.vacantRoomPointCategoryId"
                  :disabled="permission.editVacantRoomType !== 1"
                  :options="secondCategoryList4"
                  :width="120"
                ></v-select>
                <v-button text="巡查频次" v-if="patrolPoint.firstCategory4 && patrolPointConfig.vacantRoomPointCategoryId" :disabled="permission.editInspectFrequency !== 1" @click="getCategoryInfo(4)"></v-button>
              </div>
            </div>
            <!-- 未领房 -->
            <div class="col2-panel">
              <v-checkbox v-model="patrolPointConfig.isAutoGenerateUnclaimedRoomPoint" :disabled="permission.autoGenUnclaimedRoom !== 1" class="inlineB" label="自动生成未领房巡查点"></v-checkbox>
              <div class="tip">开启后，若房屋状态为未领，则会按以下规则自动生成未领房巡查点，并生成巡查任务给指定人员</div>
              <div class="point-opera" v-if="patrolPointConfig.isAutoGenerateUnclaimedRoomPoint">
                <v-select
                  class="select-container"
                  v-model="patrolPoint.firstCategory5"
                  :disabled="permission.editUnclaimedRoomTyp !== 1"
                  :options="firstCategoryList5"
                  :width="120"
                  @change="firstCategoryChange(5,$event)"
                ></v-select>
                <v-select
                  class="select-container"
                  v-model="patrolPointConfig.unclaimedRoomPointCategoryId"
                  :disabled="permission.editUnclaimedRoomTyp !== 1"
                  :options="secondCategoryList5"
                  :width="120"
                ></v-select>
                <v-button text="巡查频次" v-if="patrolPoint.firstCategory5 && patrolPointConfig.unclaimedRoomPointCategoryId" :disabled="permission.editInspectFrequency !== 1" @click="getCategoryInfo(5)"></v-button>
              </div>
            </div>
            <div class="col2-panel">
              <v-checkbox v-model="patrolPoint.autoCreatePatrolPoint" disabled class="inlineB" label="自动生成装修巡查点"></v-checkbox>
              <div class="tip">开启后，若房号进行了装修登记，则会按自动生成装修巡查点，巡查点默认分类为进场检查，并在管理端APP进行装修进场检查后，生成巡查任务给指定人员</div>
              <div class="point-opera" v-if="patrolPoint.regionId">
                <span class="left-type">{{decorationCategory}}</span>
                <v-button text="巡查频次" :disabled="permission.editInspectFrequency !== 1" @click="getCategoryInfo(2)"></v-button>
              </div>
            </div>
          </div>
      </div>
      <div slot="footer" class="footer">
        <v-button text="取消" type="default" class="left-btn" @click="dialogPointConfig = false"></v-button>
        <v-button text="确定" @click="pointConfigSubmit"></v-button>
      </div>
    </el-dialog>
    <el-dialog
        :title="categoryInfo.name"
        width="800px"
        :visible.sync="dialogPatrolClass"
      >
      <el-form :model="categoryInfo">
        <el-form-item label="巡查频次" required>
              <div class="content-wrapper">
             <el-form-item prop="period" :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
                <v-select
                class="right-wrapper"
                v-model="categoryInfo.period"
                :options="periodOps2"
                :width="182"
              ></v-select>
              </el-form-item>
            <el-form-item prop="periodNum"
              :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
                <v-input-number
                controls
                v-model="categoryInfo.periodNum"
                controls-position="right"
                placeholder="7"
                :min="0"
                :max="99"
                :width="182"
              ></v-input-number>
              </el-form-item>
            <span class="right-tip">如：“日、3”表示每日巡查3次</span>
            </div>
            <div class="down-text">{{periodTipText}}</div>
          </el-form-item>
          <el-form-item label="巡查间隔" required>
              <div class="content-wrapper">
              <el-form-item prop="timespan"
              :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
                 <v-input-number
                  controls
                  v-model="categoryInfo.timespan"
                  class="right-wrapper"
                  controls-position="right"
                  placeholder="1"
                  :min="0"
                  :max="9999999999"
                  :width="182"
                ></v-input-number>
              </el-form-item>
              <el-form-item prop="timespanUnit" :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
                <v-select
                v-model="categoryInfo.timespanUnit"
                :options="timespanOps"
                :width="182"
              ></v-select>
              </el-form-item>
            <span class="right-tip">两次巡查间隔最小时间</span>
            </div>
          </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <v-button text="取消" type="default" class="left-btn" @click="dialogPatrolClass = false"></v-button>
        <v-button text="确定" @click="patrolCategorySubmit"></v-button>
      </div>
    </el-dialog>
    <!-- 房号 multiSelect begin-->
    <multiSelect
      ref="houseMulti"
      v-bind="houseMultiSelectParams"
      :isShow.sync="houseMultiSelectParams.isShow"
      :backFill.sync="personConfig.houseIds"
      :responseOtherKey="['communityId']"
      :beforeSave="addHouseSubmit"
      :extraParams="houseMultiSelectExtraParams"
      >
      <template #searchSlot>
        <!-- <v-input v-model="houseMultiSelectParams.searchParams.communityName" label="项目名称"></v-input> -->
        <v-select label="房屋状态" v-model="houseMultiSelectParams.searchParams.roomStatus" :options="roomStatusOps"></v-select>
        <v-input v-model="houseMultiSelectParams.searchParams.blockName" label="苑"></v-input>
        <v-input v-model="houseMultiSelectParams.searchParams.buildingNumber" label="幢"></v-input>
        <v-input v-model="houseMultiSelectParams.searchParams.unit" label="单元"></v-input>
        <v-input v-model="houseMultiSelectParams.searchParams.room" label="室"></v-input>
      </template>
    </multiSelect>
    <!-- 房号 multiSelect end-->
    <!-- 人员列表 multiSelect begin-->
    <multi-select title="账号列表"
                      v-bind="personMultiSelectParams"
                      :isShow.sync="personMultiSelectParams.isShow"
                      :backFill.sync="personConfig.butlerWorkerIds"
                      :beforeSave="addPersonSubmit"
                      :extraParams="personMultiSelectExtraParams">
          <template #searchSlot>
            <v-input label="姓名"
                     v-model="personMultiSelectParams.searchParams.userName"></v-input>
            <v-input label="登录名"
                     v-model="personMultiSelectParams.searchParams.logingName"></v-input>
            <v-input label="手机号"
                     v-model="personMultiSelectParams.searchParams.phone"></v-input>
            <v-select2 label="角色名称"
                       ref="roleName"
                       :subjoin="roleExtraParams"
                       v-model="personMultiSelectParams.searchParams.roleId"
                       v-bind="roleNameParams"></v-select2>
            <v-datepicker label="创建时间"
                          type="rangedatetimer"
                          :startTime.sync="personMultiSelectParams.searchParams.startIntime"
                          :endTime.sync="personMultiSelectParams.searchParams.endIntime"
                          :maxDate="maxDate"
                          :appendToBody="false"></v-datepicker>
          </template>
        </multi-select>
    <!-- 人员列表 multiSelect end-->
    <!-- 巡查点 multiSelect begin-->
    <multiSelect
      ref="patrolPointMulti"
      v-bind="patrolPointMultiSelectParams"
      :isShow.sync="patrolPointMultiSelectParams.isShow"
      :backFill.sync="personConfig.patrolPointIds"
      :responseOtherKey="['communityId']"
      :beforeSave="addPatrolPointSubmit"
      :extraParams="houseMultiSelectExtraParams"
      >
      <template #searchSlot>
        <v-input label="巡查地点" v-model="patrolPointMultiSelectParams.searchParams.site"></v-input>
        <v-select
          label="一级分类"
          asyncOptions
          v-model="patrolPointMultiSelectParams.searchParams.firstLevel"
          :options="firstOps"
          @change="firstLevelChange"
        ></v-select>
        <v-select
          label="二级分类"
          asyncOptions
          v-model="patrolPointMultiSelectParams.searchParams.secondLevel"
          :options="secondOps"
        ></v-select>
        <v-select
          label="巡查方式"
          v-model="patrolPointMultiSelectParams.searchParams.pattern"
          :options="patternOps"
        ></v-select>
        <v-input label="负责人" v-model="patrolPointMultiSelectParams.searchParams.patrolUserName"></v-input>

      </template>
    </multiSelect>
    <!-- 巡查点 multiSelect end-->
  </div>
</template>

<script>
import {
  getPointListURL,
  exportPointListURL,
  exportPointQRCodeListURL,
  batchInspectPointOnOffURL,
  handleInspectPointOnOffURL,
  getInspectPointQRCodeURL,
  getPatrolUserHouseListURL,
  houseMultiSelectURL,
  getReceiveUserListURL,
  getRoleNameListURL,
  getOrganizeURL,
  getRoleTypeURL,
  savePatrolUserURL,
  deletePatrolUserURL,
  getUserPatrolHouseListURL,
  updatePatrolUserHouseURL,
  getCommunityHouseInfoURL,
  getCategoryIdUrl,
  getInspectPointConfigURL,
  saveInspectPointConfigURL,
  getCategoryDetailURL,
  handleInspectCategoryURL,
  getCategoryFirstURL,
  getCategorySecondURL,
  getPointInfoUrl,
  getPatrolPointListUrl,
  updatePatrolPointUrl,
  getBoundPointListUrl,
  generateInspectLogNowURL,
  batchDeletePointUrl,
} from './api'
import {
  typeOps,
  typeMap,
  statusOps,
  statusMap,
  periodOps,
  periodMap,
  patternOps,
  patternMap,
  userTypeMap,
  timespanOps
} from './map'
import {roomStatusOps, roomStatusMap} from '../projectHouseConfig/validHouse/map.js'
import QRCode from 'qrcodejs2'
import { downloadHelper } from 'common/utils'
import { QrcodeCollection, TablePanel2, MultiSelect } from 'components/bussiness/index'
import { communityParams, tenantParams } from 'common/select2Params'

//
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { vCheckbox } from 'components/control'
import { createHTMLVNode, createAlinkVNode } from 'common/vdom'
import Vue from 'vue'
import moment from 'moment'

Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

export default {
  name: '',
  components: {
    QrcodeCollection,
    vCheckbox,
    TablePanel: TablePanel2,
    MultiSelect: MultiSelect
  },
  data () {
    return {
      searchUrl: getPointListURL,
      typeOps: typeOps(1),
      statusOps: statusOps,
      periodOps: periodOps,
      periodOps2: periodOps.slice(1, periodOps.length),
      timespanOps,
      patternOps: patternOps,
      communityParams,
      tenantParams,
      searchParams: {
        site: '',
        type: undefined,
        period: undefined,
        pointStatus: undefined,
        communityId: '',
        regionId: '',
        pattern: undefined,
        startDate: this.calculateDate(false, 1),
        endDate: this.calculateDate(true, 0),
        operateStartTime: '',
        operateEndTime: '',
      },
      headers: [
        {
          prop: 'site',
          label: '巡查地点'
        },
        {
          prop: 'typeStr',
          label: '巡查类型',
          formatter (row) {
            let typeStr = typeMap[row.type]
            return typeStr
          }
        },
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'categoryName',
          label: '二级分类'
        },
        {
          prop: 'periodStr',
          label: '巡查频次',
          formatter (row) {
            let periodNum = row.periodNum
            let periodStr = '-'
            if (periodNum !== 0) {
              let period = periodMap[row.period]
              periodStr = `一${period}${periodNum}次`
            }
            return periodStr
          }
        },
        {
          prop: 'effectStartTime',
          label: '规则生效时间'
        },
        {
          prop: 'patternStr',
          label: '巡查方式',
          formatter (row) {
            let patternStr = patternMap[row.pattern]
            if(row.qrCode) {
              patternStr += row.qrCode
            }
            return patternStr
          }
        },
        {
          prop: 'qrIntime',
          label: '首次生成二维码时间',
        },
        {
          prop: 'pointStatusStr',
          label: '状态',
          formatter (row) {
            let pointStatusStr = statusMap[row.pointStatus]
            return pointStatusStr
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateUsername',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        }
      ],
      multiExport: [{
        text: '导出巡查点列表',
        method: 'delay', // 同exportMethod
        url: exportPointListURL
      }, {
        text: '导出二维码', // 导出按钮文案
        method: 'delay', // 同exportMethod
        url: exportPointQRCodeListURL // 同exportUrl
      }],
      // 二维码地点
      qrcodeSite: '',
      // 二维码值
      qrcodeValue: '',
      encryCode: '',
      qrCodeId: '',
      dialogVisible: false,
      qrcodeCollectionShow: false,
      qrcodeList: [],
      dialogPointConfig: false,
      dialogPersonConfig: false,
      patrolPoint: {
        regionId: '',
        autoCreatePatrolPoint: true,
        firstCategory: undefined,
        secondCategory: undefined,

        firstCategory4: undefined,
        secondCategory4: undefined,

        firstCategory5: undefined,
        secondCategory5: undefined,
      },
      personConfig: {
        communityId: '',
        houseIds: [],
        patrolPointIds: [], // 巡查点
        butlerWorkerIds: []
      },
      personHeader: [
        {
          prop: 'userName',
          label: '巡查人员'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'patrolCount',
          label: '巡查房号数量',
          formatter: (row, prop) => {
            if (row.patrolCount === 0) {
              return '0'
            } else {
              return createAlinkVNode(this, row, prop, {
                text: row.patrolCount,
                cb: this.lookPatrolHouses
              })
            }
          }
        },
        {
          prop: 'pointCount',
          label: '巡查点数量',
          formatter: (row, prop) => {
            if (row.pointCount === 0) {
              return '0'
            } else {
              return `${row.pointCount}`
              // return createAlinkVNode(this, row, prop, {
              //   text: row.pointCount,
              //   cb: this.lookPatrolHouses
              // })
            }
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        }
      ],
      personList: [],
      houseMultiSelectParams: {
        isShow: false,
        title: '房号列表',
        searchUrl: houseMultiSelectURL,
        showAllSelectBtn: false,
        headers: [
          {
            prop: 'communityName',
            label: '项目名称'
          },
          {
            prop: 'unitName',
            label: '单元信息'
          },
          {
            prop: '',
            label: '房屋状态',
            formatter: (row)=>{
              return roomStatusMap[row.roomStatus]
            }
          },
          {
            prop: 'address',
            label: '苑'
          },
          {
            prop: 'buildNum',
            label: '幢'
          },
          {
            prop: 'floor',
            label: '楼层'
          },
          {
            prop: 'unit',
            label: '单元'
          },
          {
            prop: 'room',
            label: '室'
          }
        ],
        searchParams: {
          communityName: '',
          blockName: '',
          buildingNumber: '',
          unit: '',
          room: '',
          roomStatus: undefined
        },
        responseParams: {
          id: 'id',
          name: 'unitName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        }
      },
      personMultiSelectParams: {
        isShow: false,
        title: '人员列表',
        searchUrl: getReceiveUserListURL,
        headers: [
          {
            prop: 'userName',
            label: '姓名'
          },
          {
            prop: 'loginName',
            label: '登录名'
          },
          {
            prop: 'phone',
            label: '手机号'
          },
          {
            prop: 'appType',
            label: '登录平台'
          },
          {
            prop: 'roleName',
            label: '角色名称'
          },
          {
            prop: 'userType',
            label: '角色类型',
            formatter: (row) => {
              return createHTMLVNode(this, userTypeMap[row.userType])
            }
          },
          {
            prop: 'orgName',
            label: '所属组织',
            formatter: (row) => {
              let text = row.orgName || '智慧园区一体化平台'
              return createHTMLVNode(this, text)
            }
          }
        ],
        responseParams: {
          id: 'id',
          name: 'orgName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        },
        searchParams: {
          userName: '',
          logingName: '',
          phone: '',
          roleType: '101',
          roleId: undefined,
          organizeId: undefined,
          startIntime: '',
          endIntime: ''
        }
      },
      patrolPointMultiSelectParams: {
        isShow: false,
        title: '巡查点列表',
        searchUrl: '',
        showAllSelectBtn: false,
        headers: [
          {
            prop: 'site',
            label: '巡查地点'
          },
          {
            prop: 'typeStr',
            label: '巡查类型',
            formatter (row) {
              let typeStr = typeMap[row.type]
              return typeStr
            }
          },
          {
            prop: 'parentName',
            label: '一级分类'
          },
          {
            prop: 'categoryName',
            label: '二级分类'
          },
          {
            prop: 'patternStr',
            label: '巡查方式',
            formatter (row) {
              let patternStr = patternMap[row.pattern]
              return patternStr
            }
          },
          {
            prop: 'patrolUserName',
            label: '负责人'
          },

          {
            prop: 'pointStatusStr',
            label: '状态',
            formatter (row) {
              let pointStatusStr = statusMap[row.pointStatus]
              return pointStatusStr
            }
          },
          {
            prop: 'communityName',
            label: '所属项目'
          },
        ],
        searchParams: {
          site: '',  // 巡查地点
          firstLevel: undefined, // 一级分类
          secondLevel: undefined, // 二级分类
          pattern: undefined, // 巡查方式
          pointStatus: 1, // 状态
          type: 1, // 巡查类型 1=> 项目巡查
          patrolUserName: '', // 负责人
        },
        responseParams: {
          id: 'id',
          name: 'name'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        }
      },
      roleNameParams: {
        searchUrl: getRoleNameListURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          roleType: '101'
        }
      },
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        },
        response: {
          text: 'name',
          value: 'id'
        },
        subjoin: {
          roleType: '101'
        }
      },
      maxDate: new Date(),
      roleTypeOps: [],
      categoryInfo: {
        level: 1,
        // 二级分类时，必填（一级分类的id）
        parentId: undefined,
        // 频次类型
        period: 1,
        // 频次
        periodNum: undefined,
        // 间隔时间
        timespan: undefined,
        // 间隔类型 1小时/2日
        timespanUnit: 1,
        name: '',
        // 默认取typeOps里第一个
        type: undefined,
        remark: ''
      },
      dialogPatrolClass: false,
      currentUser: {},
      communityHouseInfo: {},
      PointInfo: {},

      allCategoryList: [],
      allCategoryList4: [],
      allCategoryList5: [],

      firstCategoryList: [],
      firstCategoryList4: [],
      firstCategoryList5: [],

      secondCategoryList: [],
      secondCategoryList4: [],
      secondCategoryList5: [],

      patrolPointConfig: {
        decorationPointCategoryId: undefined,
        emptyRoomPointCategoryId: undefined,
        vacantRoomPointCategoryId: undefined,
        unclaimedRoomPointCategoryId: undefined,
        id: 0,
        isAutoGenerateDecorationPoint: 1,
        isAutoGenerateEmptyRoomPoint: false,
        isAutoGenerateVacantRoomPoint: false,
        isAutoGenerateUnclaimedRoomPoint: false,
        regionId: '',
        regionName: ''
      },
      decorationCategory: '',
      changeFlag: true,
      tableData: {
        resultList: []
      },
      firstOps: [ // 一级分类
        {
          text: '全部',
          value: undefined
        }
      ],
      secondOps: [  // 二级分类
        {
          text: '全部',
          value: undefined
        }
      ],
    }
  },
  created () {
    // this.getRoleType()
  },
  mounted () {
    if (this.userInfo.userType === '101') {
      this.personConfig.communityId = this.userInfo.communityId
    }
    if (this.userInfo.userType === '101') {
      this.patrolPoint.regionId = this.userInfo.regionId
    }
    if (this.userInfo.userType === '102') {
      this.patrolPoint.regionId = this.userInfo.orgId
    }
  },
  watch: {
    searchParams: {
      handler () {
        this.changeFlag = true
      },
      deep: true
    }
  },
  computed: {
    onoff () {
      let onoff = this.$store.getters.getPermission('on_off')
      return onoff
    },
    qrcode () {
      let qrcode = this.$store.getters.getPermission('qrcode')
      return qrcode
    },
    showMore () {
      return this.onoff && this.qrcode
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    permission () {
      return this.$store.state.permission
    },
    houseMultiSelectExtraParams () {
      return {
        communityId: this.personConfig.communityId
      }
    },
    personMultiSelectExtraParams () {
      return {
        organizeId: this.personConfig.communityId,
        status: 1
      }
    },
    periodTipText () {
      if (this.categoryInfo.type === 2) {
        const map = {
          '1': '将在巡查点生成后的第二天生成任务', // 日
          '2': '将在巡查点生成后的下个月一号生成任务', // 月
          '3': '将在巡查点生成后的下周一生成任务', // 周
          '4': '将在巡查点生成后的下个月一号生成任务' // 季
        }
        return this.categoryInfo.period ? map[this.categoryInfo.period] : ''
      } else {
        return ''
      }
    },
    roleExtraParams () {
      return {
        organizeId: this.personConfig.communityId,
        roleType: '101'
      }
    },
    roomStatusOps () {
      let newRoomStatusOps = roomStatusOps(1)
      let index = newRoomStatusOps.findIndex(item=>item.text=="入住")
      newRoomStatusOps.splice(index,1)
      return newRoomStatusOps
    }
  },

  methods: {
    batchDeleteHandle(SelectData) {
      let deleteList = SelectData.data.filter((item)=>{ return item.pointStatus==1})
      if(deleteList.length) {
        this.$message({
          type: 'error',
          message: '仅支持删除状态为“关闭”的巡查点!',
          center: true
        })
        return
      }
      const list = [].concat(SelectData.data);
      if(list.length === 0) {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
        return
      }

      let flag = true
      list.forEach( item => {
        if(item.type !== 1) {
          flag = false
        }
      })

      let _this = this;

      if(flag) {
        let idList = []
        list.forEach( item => {
          idList.push(item.id)
        })
        this.$confirm(`请确认是否执行此操作，删除后巡查点无法生成任务。`, {
          title: '提示'
        }).then(confirm => {
          if (confirm) {
            _this.deletePointFun(idList)
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '仅支持删除巡查类型为”项目巡查“的巡查地点，请修改后重试',
          center: true
        })
      }

    },
    deleteHandle(data) {
      if(data.type === 1) {
        this.$confirm(`请确认是否执行此操作，删除后巡查点无法生成任务。`, {
          title: '提示'
        }).then(confirm => {
          if (confirm) {
            const list = []
            list.push(data.id)
            this.deletePointFun(list)
          }
        })
      } else {
        this.$confirm('仅支持删除巡查类型为”项目巡查“的巡查地点，请修改后重试', {
          title: '提示'
        })
      }
    },
    deletePointFun(list) {
      let _this = this;
      this.$axios({
        method: 'POST',
        url: batchDeletePointUrl,
        data: list
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    getRoleType () {
      this.$axios.get(getRoleTypeURL, {}).then(async res => {
        if (res.status === '100') {
          let data = res.data
          let list = []
          data.forEach((v, i, a) => {
            list.push({
              text: v.name,
              value: v.id
            })
          })
          if (!this.personMultiSelectParams.searchParams.roleType && list.length) {
            this.personMultiSelectParams.searchParams.roleType = list[0].value
            this.roleNameParams.subjoin.roleType = list[0].value
            this.organizeParams.subjoin.roleType = list[0].value
          }
          this.roleTypeOps = list
        }
      })
    },
    // 新增/编辑
    updateAcion (row) {
      if (row) {
        this.$router.push({
          name: 'inspectPointForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          name: 'inspectPointForm'
        })
      }
    },

    // 导入的操作
    importHandle () {
      this.$router.push({
        name: 'inspectPointImport'
      })
    },

    onoffText (row) {
      return row.pointStatus === 1 ? '停用' : '启用'
    },

    // 保存二维码到本地
    downloadQRCode () {
      // downloadHelper
      let qrcodeEle = document.getElementById(this.qrCodeId)
      let url = qrcodeEle.children[1].src
      downloadHelper.downloadBySrc({
        src: url,
        fileName: this.qrcodeSite
      })
      this.dialogVisible = false
    },
    
    importClick(command) {
      let type = command.type
      if (type === 1) {
        this.toPersonPoint()
      } else if (type === 2) {
        this.toConfigPoint()
      }
    },

    // 更多的操作
    moreHandle (command) {
      // 1停用/启用 2二维码
      let type = command.type
      if (type === 1) {
        this.onOffHandle(command.row)
      } else if (type === 2) {
        this.requestForQRCode(command.row)
      }
    },

    // 启用停用操作 requestForBatchOnOff
    onOffHandle (row) {
      let _this = this
      let message =
        row.pointStatus === 1 ? '请确认是否执行此操作，停用后巡查点无法查看及执行任务' : '是否启用该巡查点？'
      this.$confirm(message, {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          _this.requestForOnOff(row)
        }
      })
    },

    // 批量启用停用操作
    batchOnOffHandle (selectedData, status) {
      // 1开启 2停用

      let list = selectedData.data || []
      if (list.length) {
        let firstStatus = list[0].pointStatus
        // 取出所选list，判断里面的数据状态是否一致
        let allSame = true
        list.forEach(item => {
          if (firstStatus !== item.pointStatus) {
            allSame = false
          }
        })
        if (allSame) {
          /// 代表所选数据状态一致，进一步判断
          if (status === firstStatus) {
            // 直接提示操作成功
            this.$message({
              type: 'success',
              message: '操作成功',
              center: true
            })
          } else {
            // 进行请求的操作
            let _this = this
            let msg = status === 2?'请确认是否执行此操作，停用后巡查点无法查看及执行任务':'确定执行该操作？'
            this.$confirm(msg, {
              title: '提示'
            }).then(confirm => {
              if (confirm) {
                let postData = {
                  ids: JSON.stringify(selectedData.ids),
                  status: status
                }
                _this.requestForBatchOnOff(postData)
              }
            })
          }
        } else {
          this.$confirm('所选数据状态不一致,请修改后重试', {
            title: '提示'
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: '请先选择要操作的数据',
          center: true
        })
      }
    },

    // 查看二维码操作
    batchQRCodeHandle (data) {
      const qrcodeList = data.filter(item => item.pattern === 2)
      if(qrcodeList.length === 0) {
        this.$message({
          type: 'error',
          message: '请选择二维码设备',
          center: true
        })
        return;
      }
      if (qrcodeList.length <= 500) {
        // 进行请求的操作
        let _this = this
        this.$confirm(`已选择${qrcodeList.length}条数据,是否全部生成二维码？`, {
          title: '提示'
        }).then(confirm => {
          if (confirm) {
            _this.requestForBatchQRCode(qrcodeList)
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: '一次最多生成500条数据，请重新选择',
          center: true
        })
      }
    },

    generateTask(data) {

      const taskList = data.data.filter(item => item.pointStatus === 1)
      console.log(data,taskList,'jjjjjdddddd')
      if(taskList.length!=data.data.length){
        this.$message({
          message: '请全部选择状态为「正常」的巡查地点，再进行批量操作!',
          type: 'warning'
        });
        return
      }
      let _this = this
      this.$confirm('该操作支持频次为日/周/月的任务生成。将立即生成大量检查任务数据，是否确定继续操作?', {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          _this.requestForGenerateTask(data)
        }
      })

    },

    requestForGenerateTask (row) {
      let _this = this
      this.$axios({
        method: 'POST',
        url: generateInspectLogNowURL,
        data: row.ids
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    handleParams(params) {
      let paramsNew = {}
      for (const paramsKey in params) {
        const element = params[paramsKey]
        if(element || element === 0) {
          paramsNew[paramsKey] = element
        }
      }
      return paramsNew
    },
    searchSuccess(res) {
      this.tableData = res.data
      this.changeFlag = false
    },
    // 启用停用请求
    requestForOnOff (row) {
      let _this = this
      let requestUrl = `${handleInspectPointOnOffURL}/${row.id}`
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      this.$axios({
        method: 'PUT',
        url: requestUrl,
        headers,
        data: this.$qs.stringify({
          type: row.pointStatus
        })
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    // 批量启用停用请求
    requestForBatchOnOff (params) {
      let _this = this
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
      this.$axios({
        method: 'PUT',
        url: batchInspectPointOnOffURL,
        headers,
        data: this.$qs.stringify(params)
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    closeDialog () {
      this.qrCodeId = ''
    },
    // 生成二维码请求
    requestForQRCode (row) {
      let _this = this
      let requestUrl = `${getInspectPointQRCodeURL}/${row.id}`
      this.$axios({
        method: 'PUT',
        url: requestUrl
      }).then(res => {
        if (res.status === 100) {
          let qrData = res.data || {}
          if (qrData.encryCode) {
            _this.dialogVisible = true
            _this.qrcodeValue = qrData.qrCode
            _this.qrcodeSite = qrData.site
            _this.encryCode = qrData.encryCode
            _this.qrCodeId = `inspect_point_qrcode_img_${qrData.qrCode}`
            // eslint-disable-next-line no-new
            this.$nextTick(() => {
              new QRCode(_this.qrCodeId, { // eslint-disable-line
                width: 200,
                height: 200,
                text: qrData.encryCode
              })
            }, 20)
          }
        }
      })
    },

    // 批量生成二维码请求
    requestForBatchQRCode (data) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: getInspectPointQRCodeURL,
        data: data.map(item => String(item.id))
      }).then(res => {
        if (res.status === 100) {
          let list = res.data || []
          if (list.length) {
            let tempList = []
            list.forEach(item => {
              tempList.push(item)
            })
            if (tempList.length) {
              _this.qrcodeList = tempList
              _this.$nextTick(() => {
                _this.qrcodeCollectionShow = true
              }, 200)
            }
          }
        }
      })
    },
    toPersonPoint () {
      if (this.userInfo.userType === '101') {
        this.getPatrolUserHouseList()
        this.getCommunityHouseInfo()
      }
      this.dialogPersonConfig = true
    },
    toConfigPoint () {
      if (this.userInfo.userType === '101' || this.userInfo.userType === '102') {
        this.getPointConfigInfo()
      }
      this.dialogPointConfig = true
    },
    addPerson () {
      this.personConfig.butlerWorkerIds = []
      this.personMultiSelectParams.isShow = true
    },
    async editPatrolHouse (row) {
      this.currentUser = row
      let { status, data } = await this.$axios.get(`${getUserPatrolHouseListURL}?userId=${row.userId}&communityId=${this.personConfig.communityId}`)
      if (status === 100) {
        this.personConfig.houseIds = data.map(item => {
          return {
            text: item.room,
            id: item.houseId
          }
        })
        this.houseMultiSelectParams.isShow = true
      }
    },
    async editPatrolPoint (row) {
      this.currentUser = row
      console.log('-----------row+++++', row)
      let { status, data } = await this.$axios.get(`${getPatrolPointListUrl}?userId=${row.userId}&communityId=${row.communityId}`)
      if (status === 100) {
        this.personConfig.patrolPointIds = data.map(item => {
          return {
            text: item.pointName,
            id: item.pointId
          }
        })
        console.info('this.personConfig', this.personConfig)
        this.requestForCategoryFirst();
        this.patrolPointMultiSelectParams.isShow = true
        this.patrolPointMultiSelectParams.searchUrl = getPointListURL
      }
    },
    async deletePatrol (row) {
      let isOk = await this.$confirm('是否确定执行此操作')
      if (!isOk) return false
      let params = {
        patrolUserId: row.id
      }
      const { status } = await this.$axios.delete(deletePatrolUserURL, { params })
      if (status === 100) {
        this.getPatrolUserHouseList()
        this.getCommunityHouseInfo()
      }
    },
    communityChange (val) {
      if (val && val.id) {
        this.getPatrolUserHouseList(val.id)
        this.getPointInfo(val.id)
        this.getCommunityHouseInfo(val.id)
      } else {
        this.personList = []
      }
    },
    async getCommunityHouseInfo (id) {
      let { status, data } = await this.$axios.get(`${getCommunityHouseInfoURL}?communityId=${id || this.personConfig.communityId}`)
      if (status === 100) {
        this.communityHouseInfo = data
      }
    },
    async getPatrolUserHouseList (id) {
      let { status, data } = await this.$axios.get(getPatrolUserHouseListURL, { params: {
        communityId: id || this.personConfig.communityId,
        curPage: 1,
        pageSize: 1000
      } })
      if (status === 100) {
        this.personList = data.resultList
      }
    },
    async getPointInfo(id) {
      let { status, data } = await this.$axios.get(`${getPointInfoUrl}?communityId=${id || this.personConfig.communityId}`)
      if (status === 100) {
        this.PointInfo = data
      }
    },
    roleTypeChange (val, item) {
      this.$refs.roleName.clearValue()
      this.$refs.organizeName.clearValue()
      this.roleNameParams.subjoin.roleType = val
      this.organizeParams.subjoin.roleType = val
    },
    // 新增人员
    async addPersonSubmit (data, ids) {
      let { status } = await this.$axios.post(savePatrolUserURL, {
        communityId: this.personConfig.communityId,
        userIdList: ids
      })
      if (status === 100) {
        this.getPatrolUserHouseList()
        return true
      } else {
        return false
      }
    },
    // 巡检人配置房号
    async addHouseSubmit (item, ids) {
      let { status: checkStatus, data: checkData } = await this.$axios.get(`${getUserPatrolHouseListURL}?userId=${this.currentUser.userId}&communityId=${this.personConfig.communityId}&houseIds=${ids.join(',')}&isExclude=1`)
      if (checkStatus === 100) {
        if (checkData.length > 0) {
          let housesText = checkData.map(item => item.room).join(',')
          let isOk = await this.$confirm(`以下${housesText}已被其他人关联，是否覆盖`)
          if (!isOk) return false
        }
      } else {
        return false
      }
      let { status } = await this.$axios.put(updatePatrolUserHouseURL, {
        communityId: this.personConfig.communityId,
        houseIdList: ids,
        patrolUserId: this.currentUser.id
      })
      if (status === 100) {
        this.getPatrolUserHouseList()
        this.getCommunityHouseInfo()
        return true
      } else {
        return false
      }
    },
    // 巡查人配置巡查点
    async addPatrolPointSubmit(item, ids) {
      let { status: checkStatus, data: checkData } = await this.$axios.get(`${getBoundPointListUrl}?userId=${this.currentUser.userId}&communityId=${this.personConfig.communityId}&pointIds=${ids.join(',')}&isExclude=1`)
      if (checkStatus === 100) {
        if (checkData.length > 0) {
          let housesText = checkData.map(item => item.name).join(',')
          let isOk = await this.$confirm(`以下${housesText}已被其他人关联，是否覆盖`)
          if (!isOk) return false
        }
      } else {
        return false
      }
      let { status } = await this.$axios.put(updatePatrolPointUrl, {
        userId: this.currentUser.userId,
        pointIds: ids,
        patrolUserId: this.currentUser.id
      })
      if (status === 100) {
        this.getPointInfo()
        this.getPatrolUserHouseList()
        return true
      } else {
        return false
      }
    },
    // 查看巡查人员的房号
    async lookPatrolHouses (row) {
      if (row.patrolCount === 0) return false
      let { status, data } = await this.$axios.get(`${getUserPatrolHouseListURL}?userId=${row.userId}&communityId=${this.personConfig.communityId}`)
      if (status === 100) {
        let text = data.map(item => item.room).join(',')
        this.$alert(text, {
          title: '巡查房号',
          closeOnClickModal: true,
          showConfirmButton: false,
          showClose: true
        })
      }
    },
    // 公司改变
    regionChange (val) {
      if (val && val.id) {
        // this.getCategory()
        this.getPointConfigInfo()
      } else {
      }
    },
    // 获取巡查点配置
    async getPointConfigInfo () {
      let { status, data } = await this.$axios.get(`${getInspectPointConfigURL}?&regionId=${this.patrolPoint.regionId}`)
      if (status === 100) {
        this.patrolPointConfig.decorationPointCategoryId = data.decorationPointCategoryId
        this.patrolPointConfig.emptyRoomPointCategoryId = data.emptyRoomPointCategoryId || undefined
        this.patrolPointConfig.vacantRoomPointCategoryId = data.vacantRoomPointCategoryId || undefined
        this.patrolPointConfig.unclaimedRoomPointCategoryId = data.unclaimedRoomPointCategoryId || undefined
        this.patrolPointConfig.id = data.id
        this.patrolPointConfig.isAutoGenerateDecorationPoint = data.isAutoGenerateDecorationPoint
        this.patrolPointConfig.isAutoGenerateEmptyRoomPoint = data.isAutoGenerateEmptyRoomPoint === 1
        this.patrolPointConfig.isAutoGenerateUnclaimedRoomPoint = data.isAutoGenerateUnclaimedRoomPoint === 1
        this.patrolPointConfig.isAutoGenerateVacantRoomPoint = data.isAutoGenerateVacantRoomPoint === 1
        this.patrolPointConfig.regionId = data.regionId
        this.patrolPointConfig.decorationPointCategoryId = data.decorationPointCategoryId
        this.getCategory(2)
        this.getCategory(4)
        this.getCategory(5)
        this.getCategoryDecoration()
      }
    },
    // 获取空关方、空置房、未领房分类
    async getCategory (type) {
      let { status, data } = await this.$axios.get(`${getCategoryIdUrl}?type=${type}&regionId=${this.patrolPoint.regionId}`)
      // 获取空关房分类
      if (status === 100 && type === 2) {
        this.allCategoryList = data
        let firstCategoryList = data.filter(item => {
          return item.level === 1
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        if (this.patrolPointConfig.emptyRoomPointCategoryId) {
          let defaultFirst = data.find(item => {
            return item.categoryId === this.patrolPointConfig.emptyRoomPointCategoryId
          }).parentId
          this.patrolPoint.firstCategory = defaultFirst
          this.getSecondCategory(2,defaultFirst)
        } else {
          this.patrolPoint.firstCategory = undefined
          this.secondCategoryList = []
        }
        this.firstCategoryList = firstCategoryList
      }
      // 获取空置房分类
      if (status === 100 && type === 4) {
        this.allCategoryList4 = data
        let firstCategoryList4 = data.filter(item => {
          return item.level === 1
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        if (this.patrolPointConfig.vacantRoomPointCategoryId) {
          let defaultFirst = data.find(item => {
            return item.categoryId === this.patrolPointConfig.vacantRoomPointCategoryId
          }).parentId
          this.patrolPoint.firstCategory4 = defaultFirst
          this.getSecondCategory(4,defaultFirst)
        } else {
          this.patrolPoint.firstCategory4 = undefined
          this.secondCategoryList4 = []
        }
        this.firstCategoryList4 = firstCategoryList4
      }
      // 未领房
      if (status === 100 && type === 5) {
        this.allCategoryList5 = data
        let firstCategoryList5 = data.filter(item => {
          return item.level === 1
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        if (this.patrolPointConfig.unclaimedRoomPointCategoryId) {
          let defaultFirst = data.find(item => {
            return item.categoryId === this.patrolPointConfig.unclaimedRoomPointCategoryId
          }).parentId
          this.patrolPoint.firstCategory5 = defaultFirst
          this.getSecondCategory(5,defaultFirst)
        } else {
          this.patrolPoint.firstCategory5 = undefined
          this.secondCategoryList5 = []
        }
        this.firstCategoryList5 = firstCategoryList5
      }
    },
    // 获取空关二级分类
    getSecondCategory (type,id) {
      if (type === 2) {
        let secondCategoryList = this.allCategoryList.filter(item => {
          return item.parentId === id && item.level === 2
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        this.secondCategoryList = secondCategoryList
        let currentCategory = secondCategoryList.filter(item => item.value === this.patrolPointConfig.emptyRoomPointCategoryId)
        if (!currentCategory[0]) {
          this.patrolPointConfig.emptyRoomPointCategoryId = undefined
        }
      }
      if (type === 4) {
        let secondCategoryList4 = this.allCategoryList4.filter(item => {
          return item.parentId === id && item.level === 2
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        this.secondCategoryList4 = secondCategoryList4
        let currentCategory = secondCategoryList4.filter(item => item.value === this.patrolPointConfig.vacantRoomPointCategoryId)
        if (!currentCategory[0]) {
          this.patrolPointConfig.vacantRoomPointCategoryId = undefined
        }
      }
      if (type === 5) {
        let secondCategoryList5 = this.allCategoryList5.filter(item => {
          return item.parentId === id && item.level === 2
        }).map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        })
        this.secondCategoryList5 = secondCategoryList5
        let currentCategory = secondCategoryList5.filter(item => item.value === this.patrolPointConfig.unclaimedRoomPointCategoryId)
        if (!currentCategory[0]) {
          this.patrolPointConfig.unclaimedRoomPointCategoryId = undefined
        }
      }
    },
    // 一级分类改变
    firstCategoryChange (type,val) {
      this.getSecondCategory(type,val)
    },

    // 获取装修分类
    async getCategoryDecoration () {
      let { status, data } = await this.$axios.get(`${getCategoryIdUrl}?type=3&regionId=${this.patrolPoint.regionId}`)
      if (status === 100) {
        let firstCategory = data.find(item => item.categoryId === this.patrolPointConfig.decorationPointCategoryId)
        let secondCategory = data.find(item => item.categoryId === firstCategory.parentId)
        this.decorationCategory = `${firstCategory.name}-${secondCategory.name}`
      }
    },
    // 巡查点配置提交
    async pointConfigSubmit () {
      if (!this.patrolPoint.regionId) {
        this.$message.error('请选择公司')
        return false
      }
      const d = this.patrolPointConfig
      let data = {
        decorationPointCategoryId: d.decorationPointCategoryId,
        emptyRoomPointCategoryId: d.emptyRoomPointCategoryId || 0,
        unclaimedRoomPointCategoryId: d.unclaimedRoomPointCategoryId || 0,
        vacantRoomPointCategoryId: d.vacantRoomPointCategoryId || 0,
        isAutoGenerateDecorationPoint: d.isAutoGenerateDecorationPoint,
        isAutoGenerateEmptyRoomPoint: d.isAutoGenerateEmptyRoomPoint ? 1 : 0,
        isAutoGenerateVacantRoomPoint: d.isAutoGenerateVacantRoomPoint ? 1 : 0,
        isAutoGenerateUnclaimedRoomPoint: d.isAutoGenerateUnclaimedRoomPoint ? 1 : 0,
        regionId: d.regionId || this.patrolPoint.regionId
      }
      if (d.id) {
        data.id = d.id
      }
      let { status } = await this.$axios.post(saveInspectPointConfigURL, data)
      if (status === 100) {
        this.dialogPointConfig = false
      }
    },
    // 获取分类详情
    async getCategoryInfo (val) {
      // let id = val === 1 ? this.patrolPointConfig.emptyRoomPointCategoryId : this.patrolPointConfig.decorationPointCategoryId
      let id = ''
      switch (val) {
        case 1:
          id = this.patrolPointConfig.emptyRoomPointCategoryId
          break;
        case 2:
          id = this.patrolPointConfig.decorationPointCategoryId
          break;
        case 4:
          id = this.patrolPointConfig.vacantRoomPointCategoryId
          break;
        case 5:
          id = this.patrolPointConfig.unclaimedRoomPointCategoryId
          break;
      }
      let { status, data } = await this.$axios.get(`${getCategoryDetailURL}/${id}`)
      if (status === 100) {
        this.categoryInfo = data
        this.dialogPatrolClass = true
      }
    },
    // 分类频次提交
    async patrolCategorySubmit () {
      let { status } = await this.$axios.put(handleInspectCategoryURL, this.categoryInfo)
      if (status === 100) {
        this.dialogPatrolClass = false
      }
    },
    dialogPointConfigClose () {
      if (this.userInfo.userType !== '101' && this.userInfo.userType !== '102') {
        this.$refs.patrolPointRegionId.clearValue()
      }
      this.patrolPoint.firstCategory = undefined
      this.patrolPoint.firstCategory4 = undefined
      this.patrolPoint.firstCategory5 = undefined
    },
    dialogPersonConfigClose () {
      if (this.userInfo.userType !== '101') {
        this.$refs.personConfigCommunityId.clearValue()
      }
    },


    firstLevelChange (value) {
      this.$set(this.patrolPointMultiSelectParams.searchParams, 'secondLevel', undefined)
      /// 一级分类改变 二级分类数据重新获取
      this.$set(this.patrolPointMultiSelectParams.searchParams, 'firstLevel', value)
      this.requestForCategorySecond()
    },

    // 获取一级分类
    requestForCategoryFirst () {
      let _this = this
      let getParams = {
        params: {
          regionId: this.patrolPoint.regionId,
          type: 1
        }
      }
      this.$axios
        .get(getCategoryFirstURL, getParams)
        .then(res => {
          if (res.status === 100) {
            // 处理一级分类
            let categoryList = res.data || []
            let tempList = [{
              text: '全部',
              value: undefined
            }]
            if (categoryList.length) {
              categoryList.forEach((item) => {
                tempList.push({
                  text: item.name,
                  value: item.categoryId
                })
              })
            }
            _this.firstOps = tempList
          }
        })
    },

    // 获取二级分类
    requestForCategorySecond () {
      let _this = this
      let getParams = {
        params: {
          parentId: this.patrolPointMultiSelectParams.searchParams.firstLevel,
          type: 1
        }
      }
      this.$axios
        .get(getCategorySecondURL, getParams)
        .then(res => {
          if (res.status === 100) {
            // 处理二级分类
            let categoryList = res.data || []
            let tempList = [{
              text: '全部',
              value: undefined
            }]
            if (categoryList.length) {
              categoryList.forEach((item) => {
                tempList.push({
                  text: item.name,
                  value: item.categoryId
                })
              })
            }
            _this.secondOps = tempList
          }
        })
    },
    // 批量修改
    batchEdit() {
      this.$router.push({
        name: 'inspectPointBatchEdit',
      })
    },

    // 计算时间,type类型，days天数
    calculateDate(add, days){
      // true加 false减
      if (add) {
        return moment().add(days, 'd').format('YYYY-MM-DD HH:mm')
      } else {
        return moment().subtract(days, 'months').format('YYYY-MM-DD HH:mm')
      }
    },
  }
}
</script>

<style scoped lang="scss">
.inspectManagerPointList-wrapper {
  .qrcode-wrapper {
    display: block;

    .title-text {
      margin-top: -10px;
      margin-bottom: 10px;
      text-align: center;
      font-size: 24px;
    }

    .qrcode-value {
      padding-top: 10px;
      text-align: center;
      border-top: 1px dotted #333;
    }

    .qrcode-img-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      text-align: center;
      padding: 10px 0;
    }
  }
}
.patrol-point-wrapper {
  text-align: left;
  .point-panel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .col2-panel {
      margin-top: 10px;
      flex: 1;
      .tip {
        color: #999;
        width: 300px;
      }
      .point-opera {
        margin-top: 10px;
        .select-container {
          margin-right: 10px;
        }
        .left-type {
          display: inline-block;
          width: 200px;
        }
      }
    }
  }
  .count-label {
    margin: 20px;
    .red-color {
      color: #ff0000;
    }
  }
  .add-box {
    margin: 10px 0;
  }

  .el-dropdown-link {
    color: #409EFF;
    cursor: pointer;
  }
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .left-btn {
    margin-right: 10px;
  }
}
.content-wrapper {
  display: flex;
  justify-content: flex-start;

  .right-wrapper {
    margin-right: 30px;
  }

  .right-tip {
    margin-left: 15px;
  }
}
.down-text {
  text-align: left;
}
</style>
