var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectManagerPointList-wrapper" },
    [
      _c(
        "list",
        {
          ref: "list",
          attrs: {
            exportPermission: "export",
            searchUrl: _vm.searchUrl,
            searchParams: _vm.searchParams,
            headers: _vm.headers,
            isMultiSelect: true,
            multiExport: _vm.multiExport,
            "page-size": 200,
          },
          on: {
            "update:searchParams": function ($event) {
              _vm.searchParams = $event
            },
            "update:search-params": function ($event) {
              _vm.searchParams = $event
            },
            searchSuccess: _vm.searchSuccess,
          },
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function (scope) {
                return [
                  _c("v-button", {
                    attrs: {
                      text: "生成本次巡查任务",
                      permission: "generateNow",
                      disabled: scope.selectedData.data.length == 0,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.generateTask(scope.selectedData)
                      },
                    },
                  }),
                  _c("v-button", {
                    attrs: { text: "批量修改", permission: "update" },
                    on: { click: _vm.batchEdit },
                  }),
                  _vm.permission.inspectUserConfig || _vm.permission.pointConfig
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.importClick },
                        },
                        [
                          _c("v-button", [
                            _vm._v("\n          巡查配置"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.permission.inspectUserConfig,
                                      expression:
                                        "permission.inspectUserConfig",
                                    },
                                  ],
                                  attrs: { command: { type: 1 } },
                                },
                                [
                                  _c("v-button", { attrs: { type: "text" } }, [
                                    _vm._v("巡查人员配置"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.permission.pointConfig,
                                      expression: "permission.pointConfig",
                                    },
                                  ],
                                  attrs: { command: { type: 2 } },
                                },
                                [
                                  _c("v-button", { attrs: { type: "text" } }, [
                                    _vm._v("巡查点配置"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-button", {
                    attrs: { text: "新增", permission: "add" },
                    on: { click: _vm.updateAcion },
                  }),
                  _c("v-button", {
                    attrs: { text: "导入", permission: "import" },
                    on: { click: _vm.importHandle },
                  }),
                ]
              },
            },
            {
              key: "searchSlot",
              fn: function () {
                return [
                  _c("v-input", {
                    attrs: { label: "巡查地点" },
                    model: {
                      value: _vm.searchParams.site,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "site", $$v)
                      },
                      expression: "searchParams.site",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "巡查类型", options: _vm.typeOps },
                    model: {
                      value: _vm.searchParams.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "type", $$v)
                      },
                      expression: "searchParams.type",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "巡查频次", options: _vm.periodOps },
                    model: {
                      value: _vm.searchParams.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "period", $$v)
                      },
                      expression: "searchParams.period",
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "状态", options: _vm.statusOps },
                    model: {
                      value: _vm.searchParams.pointStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "pointStatus", $$v)
                      },
                      expression: "searchParams.pointStatus",
                    },
                  }),
                  _c("v-datepicker", {
                    attrs: {
                      label: "规则生效时间段",
                      startTime: _vm.searchParams.startDate,
                      endTime: _vm.searchParams.endDate,
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(_vm.searchParams, "startDate", $event)
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(_vm.searchParams, "startDate", $event)
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(_vm.searchParams, "endDate", $event)
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(_vm.searchParams, "endDate", $event)
                      },
                    },
                  }),
                  _c("v-select", {
                    attrs: { label: "巡查方式", options: _vm.patternOps },
                    model: {
                      value: _vm.searchParams.pattern,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams, "pattern", $$v)
                      },
                      expression: "searchParams.pattern",
                    },
                  }),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: { label: "所属项目" },
                        model: {
                          value: _vm.searchParams.communityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "communityId", $$v)
                          },
                          expression: "searchParams.communityId",
                        },
                      },
                      "v-select2",
                      _vm.communityParams,
                      false
                    )
                  ),
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        attrs: { label: "所属公司" },
                        model: {
                          value: _vm.searchParams.regionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "regionId", $$v)
                          },
                          expression: "searchParams.regionId",
                        },
                      },
                      "v-select2",
                      _vm.tenantParams,
                      false
                    )
                  ),
                  _c("v-datepicker", {
                    attrs: {
                      label: "最后操作时间",
                      type: "rangedatetimer",
                      startTime: _vm.searchParams.operateStartTime,
                      endTime: _vm.searchParams.operateEndTime,
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "operateStartTime",
                          $event
                        )
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "operateStartTime",
                          $event
                        )
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "operateEndTime",
                          $event
                        )
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(
                          _vm.searchParams,
                          "operateEndTime",
                          $event
                        )
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "operateSlot",
              fn: function (scope) {
                return [
                  _c("v-button", {
                    attrs: { text: "编辑", type: "text", permission: "update" },
                    on: {
                      click: function ($event) {
                        return _vm.updateAcion(scope.row)
                      },
                    },
                  }),
                  scope.row.pointStatus == 2
                    ? _c("v-button", {
                        attrs: {
                          text: "删除",
                          type: "text",
                          permission: "delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteHandle(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.showMore
                    ? _c("v-button", {
                        attrs: {
                          text: _vm.onoffText(scope.row),
                          type: "text",
                          permission: "on_off",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.batchOnOffHandle(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.showMore
                    ? _c("v-button", {
                        attrs: {
                          text: "二维码",
                          type: "text",
                          permission: "qrcode",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.lookQRCodeHandle(scope.row)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showMore
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.moreHandle },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v("\n          更多"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { row: scope.row, type: 1 },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.onoffText(scope.row))),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  attrs: {
                                    command: { row: scope.row, type: 2 },
                                  },
                                },
                                [_c("span", [_vm._v("二维码")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "batchSlot",
              fn: function (scope) {
                return [
                  _c("v-button", {
                    attrs: {
                      text: "批量生成二维码",
                      disabled: scope.selectedData.data.length === 0,
                      permission: "qrcode",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.batchQRCodeHandle(scope.selectedData.data)
                      },
                    },
                  }),
                  _c("v-button", {
                    attrs: { text: "批量开启", permission: "on_off" },
                    on: {
                      click: function ($event) {
                        return _vm.batchOnOffHandle(scope.selectedData, 1)
                      },
                    },
                  }),
                  _c("v-button", {
                    attrs: { text: "批量停用", permission: "on_off" },
                    on: {
                      click: function ($event) {
                        return _vm.batchOnOffHandle(scope.selectedData, 2)
                      },
                    },
                  }),
                  _c("v-button", {
                    attrs: { text: "批量删除", permission: "batchDelete" },
                    on: {
                      click: function ($event) {
                        return _vm.batchDeleteHandle(scope.selectedData)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "下载二维码",
                width: "500px",
                visible: _vm.dialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                closed: _vm.closeDialog,
              },
            },
            [
              _c(
                "div",
                { staticClass: "qrcode-wrapper" },
                [
                  _c("div", [
                    _c("h3", { staticClass: "title-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.qrcodeSite) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "qrcode-value" }, [
                    _c("span", [
                      _vm._v(" 二维码值：" + _vm._s(_vm.qrcodeValue) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "qrcode-img-wrapper" }, [
                    _vm.qrCodeId
                      ? _c("div", {
                          staticClass: "img",
                          attrs: { id: _vm.qrCodeId },
                        })
                      : _vm._e(),
                  ]),
                  _c("v-button", {
                    attrs: { text: "保存到本地", type: "success" },
                    on: { click: _vm.downloadQRCode },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("qrcode-collection", {
            attrs: {
              "qrcode-list": _vm.qrcodeList,
              visible: _vm.qrcodeCollectionShow,
            },
            on: {
              "update:visible": function ($event) {
                _vm.qrcodeCollectionShow = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "qrcodeSlot",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.site))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "巡查人员配置",
            width: "800px",
            visible: _vm.dialogPersonConfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPersonConfig = $event
            },
            close: _vm.dialogPersonConfigClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "patrol-point-wrapper" },
            [
              _vm.userInfo.userType !== "101"
                ? _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "personConfigCommunityId",
                        attrs: { label: "所属项目", placeholder: "查询项目" },
                        on: { onChange: _vm.communityChange },
                        model: {
                          value: _vm.personConfig.communityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.personConfig, "communityId", $$v)
                          },
                          expression: "personConfig.communityId",
                        },
                      },
                      "v-select2",
                      _vm.communityParams,
                      false
                    )
                  )
                : _vm._e(),
              _vm.personConfig.communityId
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "count-label" }, [
                        _vm._v(
                          "\n          项目共 " +
                            _vm._s(_vm.communityHouseInfo.houseCount) +
                            " 个房号，尚未指定巡查人员的房号有 "
                        ),
                        _c("span", { staticClass: "red-color" }, [
                          _vm._v(_vm._s(_vm.communityHouseInfo.nonPatrolCount)),
                        ]),
                        _vm._v(" 个\n        "),
                      ]),
                      _c("div", { staticClass: "count-label" }, [
                        _vm._v(
                          "\n          项目共 " +
                            _vm._s(_vm.PointInfo.pointCount) +
                            " 个巡查点，尚未指定巡查人员的点位有 "
                        ),
                        _c("span", { staticClass: "red-color" }, [
                          _vm._v(_vm._s(_vm.PointInfo.nonPatrolCount)),
                        ]),
                        _vm._v(" 个\n        "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "add-box" },
                        [
                          _c("v-button", {
                            attrs: { text: "新增人员" },
                            on: { click: _vm.addPerson },
                          }),
                        ],
                        1
                      ),
                      _c("table-panel", {
                        ref: "personTable",
                        attrs: {
                          headers: _vm.personHeader,
                          maxHeight: "300px",
                          tableData: _vm.personList,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "operateSlot",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    { attrs: { trigger: "click" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "el-dropdown-link" },
                                        [_vm._v("编辑")]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c("v-button", {
                                                attrs: {
                                                  text: "编辑巡查房号",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editPatrolHouse(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c("v-button", {
                                                attrs: {
                                                  text: "编辑巡查点",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editPatrolPoint(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v("\n             \n            "),
                                  _c("v-button", {
                                    attrs: { text: "删除", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletePatrol(scope.row)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4210989270
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "巡查点配置",
            width: "800px",
            visible: _vm.dialogPointConfig,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPointConfig = $event
            },
            close: _vm.dialogPointConfigClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "patrol-point-wrapper" },
            [
              _vm.userInfo.userType !== "101" && _vm.userInfo.userType !== "102"
                ? _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "patrolPointRegionId",
                        attrs: { label: "所属公司" },
                        on: { onChange: _vm.regionChange },
                        model: {
                          value: _vm.patrolPoint.regionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.patrolPoint, "regionId", $$v)
                          },
                          expression: "patrolPoint.regionId",
                        },
                      },
                      "v-select2",
                      _vm.tenantParams,
                      false
                    )
                  )
                : _vm._e(),
              _vm.patrolPoint.regionId ||
              _vm.userInfo.userType === "102" ||
              _vm.userInfo.userType === "101"
                ? _c("div", { staticClass: "point-panel" }, [
                    _c(
                      "div",
                      { staticClass: "col2-panel" },
                      [
                        _c("v-checkbox", {
                          staticClass: "inlineB",
                          attrs: {
                            disabled: _vm.permission.autoGenEmptyRoom !== 1,
                            label: "自动生成空关房巡查点",
                          },
                          model: {
                            value:
                              _vm.patrolPointConfig
                                .isAutoGenerateEmptyRoomPoint,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patrolPointConfig,
                                "isAutoGenerateEmptyRoomPoint",
                                $$v
                              )
                            },
                            expression:
                              "patrolPointConfig.isAutoGenerateEmptyRoomPoint",
                          },
                        }),
                        _c("div", { staticClass: "tip" }, [
                          _vm._v(
                            "开启后，若房屋状态为空关，则会按以下规则自动生成空关房巡查点，并生成巡查任务给指定人员"
                          ),
                        ]),
                        _vm.patrolPointConfig.isAutoGenerateEmptyRoomPoint
                          ? _c(
                              "div",
                              { staticClass: "point-opera" },
                              [
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editEmptyRoomType !== 1,
                                    options: _vm.firstCategoryList,
                                    width: 120,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.firstCategoryChange(2, $event)
                                    },
                                  },
                                  model: {
                                    value: _vm.patrolPoint.firstCategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPoint,
                                        "firstCategory",
                                        $$v
                                      )
                                    },
                                    expression: "patrolPoint.firstCategory",
                                  },
                                }),
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editEmptyRoomType !== 1,
                                    options: _vm.secondCategoryList,
                                    width: 120,
                                  },
                                  model: {
                                    value:
                                      _vm.patrolPointConfig
                                        .emptyRoomPointCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPointConfig,
                                        "emptyRoomPointCategoryId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "patrolPointConfig.emptyRoomPointCategoryId",
                                  },
                                }),
                                _vm.patrolPoint.firstCategory &&
                                _vm.patrolPointConfig.emptyRoomPointCategoryId
                                  ? _c("v-button", {
                                      attrs: {
                                        text: "巡查频次",
                                        disabled:
                                          _vm.permission
                                            .editInspectFrequency !== 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCategoryInfo(1)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col2-panel" },
                      [
                        _c("v-checkbox", {
                          staticClass: "inlineB",
                          attrs: {
                            disabled: _vm.permission.autoGenVacantRoom !== 1,
                            label: "自动生成空置房巡查点",
                          },
                          model: {
                            value:
                              _vm.patrolPointConfig
                                .isAutoGenerateVacantRoomPoint,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patrolPointConfig,
                                "isAutoGenerateVacantRoomPoint",
                                $$v
                              )
                            },
                            expression:
                              "patrolPointConfig.isAutoGenerateVacantRoomPoint",
                          },
                        }),
                        _c("div", { staticClass: "tip" }, [
                          _vm._v(
                            "开启后，若房屋状态为空置，则会按以下规则自动生成空置房巡查点，并生成巡查任务给指定人员"
                          ),
                        ]),
                        _vm.patrolPointConfig.isAutoGenerateVacantRoomPoint
                          ? _c(
                              "div",
                              { staticClass: "point-opera" },
                              [
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editVacantRoomType !== 1,
                                    options: _vm.firstCategoryList4,
                                    width: 120,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.firstCategoryChange(4, $event)
                                    },
                                  },
                                  model: {
                                    value: _vm.patrolPoint.firstCategory4,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPoint,
                                        "firstCategory4",
                                        $$v
                                      )
                                    },
                                    expression: "patrolPoint.firstCategory4",
                                  },
                                }),
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editVacantRoomType !== 1,
                                    options: _vm.secondCategoryList4,
                                    width: 120,
                                  },
                                  model: {
                                    value:
                                      _vm.patrolPointConfig
                                        .vacantRoomPointCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPointConfig,
                                        "vacantRoomPointCategoryId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "patrolPointConfig.vacantRoomPointCategoryId",
                                  },
                                }),
                                _vm.patrolPoint.firstCategory4 &&
                                _vm.patrolPointConfig.vacantRoomPointCategoryId
                                  ? _c("v-button", {
                                      attrs: {
                                        text: "巡查频次",
                                        disabled:
                                          _vm.permission
                                            .editInspectFrequency !== 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCategoryInfo(4)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col2-panel" },
                      [
                        _c("v-checkbox", {
                          staticClass: "inlineB",
                          attrs: {
                            disabled: _vm.permission.autoGenUnclaimedRoom !== 1,
                            label: "自动生成未领房巡查点",
                          },
                          model: {
                            value:
                              _vm.patrolPointConfig
                                .isAutoGenerateUnclaimedRoomPoint,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patrolPointConfig,
                                "isAutoGenerateUnclaimedRoomPoint",
                                $$v
                              )
                            },
                            expression:
                              "patrolPointConfig.isAutoGenerateUnclaimedRoomPoint",
                          },
                        }),
                        _c("div", { staticClass: "tip" }, [
                          _vm._v(
                            "开启后，若房屋状态为未领，则会按以下规则自动生成未领房巡查点，并生成巡查任务给指定人员"
                          ),
                        ]),
                        _vm.patrolPointConfig.isAutoGenerateUnclaimedRoomPoint
                          ? _c(
                              "div",
                              { staticClass: "point-opera" },
                              [
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editUnclaimedRoomTyp !== 1,
                                    options: _vm.firstCategoryList5,
                                    width: 120,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.firstCategoryChange(5, $event)
                                    },
                                  },
                                  model: {
                                    value: _vm.patrolPoint.firstCategory5,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPoint,
                                        "firstCategory5",
                                        $$v
                                      )
                                    },
                                    expression: "patrolPoint.firstCategory5",
                                  },
                                }),
                                _c("v-select", {
                                  staticClass: "select-container",
                                  attrs: {
                                    disabled:
                                      _vm.permission.editUnclaimedRoomTyp !== 1,
                                    options: _vm.secondCategoryList5,
                                    width: 120,
                                  },
                                  model: {
                                    value:
                                      _vm.patrolPointConfig
                                        .unclaimedRoomPointCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.patrolPointConfig,
                                        "unclaimedRoomPointCategoryId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "patrolPointConfig.unclaimedRoomPointCategoryId",
                                  },
                                }),
                                _vm.patrolPoint.firstCategory5 &&
                                _vm.patrolPointConfig
                                  .unclaimedRoomPointCategoryId
                                  ? _c("v-button", {
                                      attrs: {
                                        text: "巡查频次",
                                        disabled:
                                          _vm.permission
                                            .editInspectFrequency !== 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getCategoryInfo(5)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col2-panel" },
                      [
                        _c("v-checkbox", {
                          staticClass: "inlineB",
                          attrs: { disabled: "", label: "自动生成装修巡查点" },
                          model: {
                            value: _vm.patrolPoint.autoCreatePatrolPoint,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.patrolPoint,
                                "autoCreatePatrolPoint",
                                $$v
                              )
                            },
                            expression: "patrolPoint.autoCreatePatrolPoint",
                          },
                        }),
                        _c("div", { staticClass: "tip" }, [
                          _vm._v(
                            "开启后，若房号进行了装修登记，则会按自动生成装修巡查点，巡查点默认分类为进场检查，并在管理端APP进行装修进场检查后，生成巡查任务给指定人员"
                          ),
                        ]),
                        _vm.patrolPoint.regionId
                          ? _c(
                              "div",
                              { staticClass: "point-opera" },
                              [
                                _c("span", { staticClass: "left-type" }, [
                                  _vm._v(_vm._s(_vm.decorationCategory)),
                                ]),
                                _c("v-button", {
                                  attrs: {
                                    text: "巡查频次",
                                    disabled:
                                      _vm.permission.editInspectFrequency !== 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCategoryInfo(2)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                staticClass: "left-btn",
                attrs: { text: "取消", type: "default" },
                on: {
                  click: function ($event) {
                    _vm.dialogPointConfig = false
                  },
                },
              }),
              _c("v-button", {
                attrs: { text: "确定" },
                on: { click: _vm.pointConfigSubmit },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.categoryInfo.name,
            width: "800px",
            visible: _vm.dialogPatrolClass,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPatrolClass = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.categoryInfo } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "巡查频次", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "content-wrapper" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "period",
                            rules: {
                              required: true,
                              message: "当前选项不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "right-wrapper",
                            attrs: { options: _vm.periodOps2, width: 182 },
                            model: {
                              value: _vm.categoryInfo.period,
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryInfo, "period", $$v)
                              },
                              expression: "categoryInfo.period",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "periodNum",
                            rules: {
                              required: true,
                              message: "内容不允许为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              controls: "",
                              "controls-position": "right",
                              placeholder: "7",
                              min: 0,
                              max: 99,
                              width: 182,
                            },
                            model: {
                              value: _vm.categoryInfo.periodNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryInfo, "periodNum", $$v)
                              },
                              expression: "categoryInfo.periodNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "right-tip" }, [
                        _vm._v("如：“日、3”表示每日巡查3次"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "down-text" }, [
                    _vm._v(_vm._s(_vm.periodTipText)),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "巡查间隔", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "content-wrapper" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "timespan",
                            rules: {
                              required: true,
                              message: "内容不允许为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("v-input-number", {
                            staticClass: "right-wrapper",
                            attrs: {
                              controls: "",
                              "controls-position": "right",
                              placeholder: "1",
                              min: 0,
                              max: 9999999999,
                              width: 182,
                            },
                            model: {
                              value: _vm.categoryInfo.timespan,
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryInfo, "timespan", $$v)
                              },
                              expression: "categoryInfo.timespan",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "timespanUnit",
                            rules: {
                              required: true,
                              message: "当前选项不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.timespanOps, width: 182 },
                            model: {
                              value: _vm.categoryInfo.timespanUnit,
                              callback: function ($$v) {
                                _vm.$set(_vm.categoryInfo, "timespanUnit", $$v)
                              },
                              expression: "categoryInfo.timespanUnit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "right-tip" }, [
                        _vm._v("两次巡查间隔最小时间"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                staticClass: "left-btn",
                attrs: { text: "取消", type: "default" },
                on: {
                  click: function ($event) {
                    _vm.dialogPatrolClass = false
                  },
                },
              }),
              _c("v-button", {
                attrs: { text: "确定" },
                on: { click: _vm.patrolCategorySubmit },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "multiSelect",
        _vm._b(
          {
            ref: "houseMulti",
            attrs: {
              isShow: _vm.houseMultiSelectParams.isShow,
              backFill: _vm.personConfig.houseIds,
              responseOtherKey: ["communityId"],
              beforeSave: _vm.addHouseSubmit,
              extraParams: _vm.houseMultiSelectExtraParams,
            },
            on: {
              "update:isShow": function ($event) {
                return _vm.$set(_vm.houseMultiSelectParams, "isShow", $event)
              },
              "update:is-show": function ($event) {
                return _vm.$set(_vm.houseMultiSelectParams, "isShow", $event)
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.personConfig, "houseIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.personConfig, "houseIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-select", {
                      attrs: { label: "房屋状态", options: _vm.roomStatusOps },
                      model: {
                        value:
                          _vm.houseMultiSelectParams.searchParams.roomStatus,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.houseMultiSelectParams.searchParams,
                            "roomStatus",
                            $$v
                          )
                        },
                        expression:
                          "houseMultiSelectParams.searchParams.roomStatus",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "苑" },
                      model: {
                        value:
                          _vm.houseMultiSelectParams.searchParams.blockName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.houseMultiSelectParams.searchParams,
                            "blockName",
                            $$v
                          )
                        },
                        expression:
                          "houseMultiSelectParams.searchParams.blockName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "幢" },
                      model: {
                        value:
                          _vm.houseMultiSelectParams.searchParams
                            .buildingNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.houseMultiSelectParams.searchParams,
                            "buildingNumber",
                            $$v
                          )
                        },
                        expression:
                          "houseMultiSelectParams.searchParams.buildingNumber",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "单元" },
                      model: {
                        value: _vm.houseMultiSelectParams.searchParams.unit,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.houseMultiSelectParams.searchParams,
                            "unit",
                            $$v
                          )
                        },
                        expression: "houseMultiSelectParams.searchParams.unit",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "室" },
                      model: {
                        value: _vm.houseMultiSelectParams.searchParams.room,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.houseMultiSelectParams.searchParams,
                            "room",
                            $$v
                          )
                        },
                        expression: "houseMultiSelectParams.searchParams.room",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multiSelect",
          _vm.houseMultiSelectParams,
          false
        )
      ),
      _c(
        "multi-select",
        _vm._b(
          {
            attrs: {
              title: "账号列表",
              isShow: _vm.personMultiSelectParams.isShow,
              backFill: _vm.personConfig.butlerWorkerIds,
              beforeSave: _vm.addPersonSubmit,
              extraParams: _vm.personMultiSelectExtraParams,
            },
            on: {
              "update:isShow": function ($event) {
                return _vm.$set(_vm.personMultiSelectParams, "isShow", $event)
              },
              "update:is-show": function ($event) {
                return _vm.$set(_vm.personMultiSelectParams, "isShow", $event)
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.personConfig, "butlerWorkerIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.personConfig, "butlerWorkerIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "姓名" },
                      model: {
                        value:
                          _vm.personMultiSelectParams.searchParams.userName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "userName",
                            $$v
                          )
                        },
                        expression:
                          "personMultiSelectParams.searchParams.userName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "登录名" },
                      model: {
                        value:
                          _vm.personMultiSelectParams.searchParams.logingName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "logingName",
                            $$v
                          )
                        },
                        expression:
                          "personMultiSelectParams.searchParams.logingName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "手机号" },
                      model: {
                        value: _vm.personMultiSelectParams.searchParams.phone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "phone",
                            $$v
                          )
                        },
                        expression:
                          "personMultiSelectParams.searchParams.phone",
                      },
                    }),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          ref: "roleName",
                          attrs: {
                            label: "角色名称",
                            subjoin: _vm.roleExtraParams,
                          },
                          model: {
                            value:
                              _vm.personMultiSelectParams.searchParams.roleId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personMultiSelectParams.searchParams,
                                "roleId",
                                $$v
                              )
                            },
                            expression:
                              "personMultiSelectParams.searchParams.roleId",
                          },
                        },
                        "v-select2",
                        _vm.roleNameParams,
                        false
                      )
                    ),
                    _c("v-datepicker", {
                      attrs: {
                        label: "创建时间",
                        type: "rangedatetimer",
                        startTime:
                          _vm.personMultiSelectParams.searchParams.startIntime,
                        endTime:
                          _vm.personMultiSelectParams.searchParams.endIntime,
                        maxDate: _vm.maxDate,
                        appendToBody: false,
                      },
                      on: {
                        "update:startTime": function ($event) {
                          return _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "startIntime",
                            $event
                          )
                        },
                        "update:start-time": function ($event) {
                          return _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "startIntime",
                            $event
                          )
                        },
                        "update:endTime": function ($event) {
                          return _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "endIntime",
                            $event
                          )
                        },
                        "update:end-time": function ($event) {
                          return _vm.$set(
                            _vm.personMultiSelectParams.searchParams,
                            "endIntime",
                            $event
                          )
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select",
          _vm.personMultiSelectParams,
          false
        )
      ),
      _c(
        "multiSelect",
        _vm._b(
          {
            ref: "patrolPointMulti",
            attrs: {
              isShow: _vm.patrolPointMultiSelectParams.isShow,
              backFill: _vm.personConfig.patrolPointIds,
              responseOtherKey: ["communityId"],
              beforeSave: _vm.addPatrolPointSubmit,
              extraParams: _vm.houseMultiSelectExtraParams,
            },
            on: {
              "update:isShow": function ($event) {
                return _vm.$set(
                  _vm.patrolPointMultiSelectParams,
                  "isShow",
                  $event
                )
              },
              "update:is-show": function ($event) {
                return _vm.$set(
                  _vm.patrolPointMultiSelectParams,
                  "isShow",
                  $event
                )
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.personConfig, "patrolPointIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.personConfig, "patrolPointIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "巡查地点" },
                      model: {
                        value:
                          _vm.patrolPointMultiSelectParams.searchParams.site,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patrolPointMultiSelectParams.searchParams,
                            "site",
                            $$v
                          )
                        },
                        expression:
                          "patrolPointMultiSelectParams.searchParams.site",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "一级分类",
                        asyncOptions: "",
                        options: _vm.firstOps,
                      },
                      on: { change: _vm.firstLevelChange },
                      model: {
                        value:
                          _vm.patrolPointMultiSelectParams.searchParams
                            .firstLevel,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patrolPointMultiSelectParams.searchParams,
                            "firstLevel",
                            $$v
                          )
                        },
                        expression:
                          "patrolPointMultiSelectParams.searchParams.firstLevel",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        label: "二级分类",
                        asyncOptions: "",
                        options: _vm.secondOps,
                      },
                      model: {
                        value:
                          _vm.patrolPointMultiSelectParams.searchParams
                            .secondLevel,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patrolPointMultiSelectParams.searchParams,
                            "secondLevel",
                            $$v
                          )
                        },
                        expression:
                          "patrolPointMultiSelectParams.searchParams.secondLevel",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "巡查方式", options: _vm.patternOps },
                      model: {
                        value:
                          _vm.patrolPointMultiSelectParams.searchParams.pattern,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patrolPointMultiSelectParams.searchParams,
                            "pattern",
                            $$v
                          )
                        },
                        expression:
                          "patrolPointMultiSelectParams.searchParams.pattern",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "负责人" },
                      model: {
                        value:
                          _vm.patrolPointMultiSelectParams.searchParams
                            .patrolUserName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.patrolPointMultiSelectParams.searchParams,
                            "patrolUserName",
                            $$v
                          )
                        },
                        expression:
                          "patrolPointMultiSelectParams.searchParams.patrolUserName",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multiSelect",
          _vm.patrolPointMultiSelectParams,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }